import axios from "axios";
import { useState } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import info from "../info";

function Contact() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const initForm = { name: "", email: "", message: "" };
  const [form, setForm] = useState(initForm);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!executeRecaptcha) {
        toast.error("Recaptcha has not been loaded");
        return;
      }
      const { name, email, message } = form;
      if (!name || !email || !message) {
        toast.error("Please fill all required fields.");
        return;
      }
      const token = await executeRecaptcha("submit");
      const response = await axios.post(info.apiUrl + "/contact", form);
      if (response.error) {
        toast.error("Message couldn't send");
      } else {
        toast.success("Your message sent successfully");
        setForm(initForm);
      }
    } catch (error) {
      toast.error("There is an error");
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setForm((b) => ({ ...b, [e.target.name]: e.target.value }));
  };
  return (
    <>
      <div className="banner">
        <div className="container kenar">
          <h1 className="renk">
            <span className="flash">Contact Us</span>
          </h1>
          <br />
          <br />
          <h5>
            We are here to help! Please do not hasitate if you need help or if
            you have question about Coinbuilder
          </h5>

          <br />
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="card p-3" style={{ background: "lightgray" }}>
            <div className="card-body mb-5 mt-3">
              <div className="col-md-12 text-center mt-2 mb-5">
                <h5>
                  Please submit form to contact us. We will respond shortly.
                </h5>
              </div>
              <hr />

              <div className="col-md-12 text-center">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 mt-2 mb-2">
                      <label htmlFor="">Your Name</label>
                      <input
                        name="name"
                        type="text"
                        onChange={handleChange}
                        value={form.name}
                        className="form-control mt-2 mb-2"
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-2">
                      <label htmlFor="">Your Email</label>
                      <input
                        name="email"
                        type="text"
                        value={form.email}
                        onChange={handleChange}
                        className="form-control mt-2 mb-2"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-2 mb-2">
                    <label htmlFor="">Your Message</label>
                    <textarea
                      name="message"
                      onChange={handleChange}
                      rows={8}
                      value={form.message}
                      className="form-control mt-2 mb-2"
                    />
                  </div>
                  <button
                    className="mt-3 mb-3 text-center btn-token"
                    type="submit"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
