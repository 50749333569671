import React from "react";

function Guide() {
  return (
    <>
      <div className="banner">
        <div className="container kenar">
          <h1 className="renk">
            <span className="flash">Guide</span>
          </h1>
          <br />
          <br />
          <h5>
            Step-by-Step Guide to Create a Basic Token Using CoinBuilder.pro
          </h5>

          <br />
          <br />
        </div>
      </div>
      <div className="container p-5">
        <p>
          <strong>1). Go to the CoinBuilder.pro Website</strong>
        </p>
        <ul className="list-unstyled">
          <li>
            <p>Open your preferred web browser.</p>
          </li>
          <li>
            <p>
              Navigate to{" "}
              <a href="https://www.coinbuilder.pro/" target="_blank">
                <u>CoinBuilder.pro</u>
              </a>
              .
            </p>
          </li>
        </ul>
        <p>
          <strong>2). Create Your Token</strong>
        </p>
        <ul className="list-unstyled">
          <li>
            <p>
              <strong>Connect Your Wallet:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>
                  You will need to connect a wallet like MetaMask to deploy the
                  token.
                  <a href="https://metamask.io/">
                    <u>https://metamask.io/</u>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Click on Connect Wallet button in the upper right corner of
                  CoinBuilder.pro
                </p>
              </li>
              <li>
                <p>
                  Follow the prompts to connect your MetaMask wallet to
                  CoinBuilder.pro.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Start Token Creation:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>Click on the "Create Token" button on the dashboard.</p>
              </li>
              <li>
                <p>
                  Choose the blockchain you want to create your token on (e.g.,
                  Ethereum, Binance Smart Chain).
                </p>
              </li>
              <li>
                <p>Fill in the basic details:</p>
                <ul className="list-unstyled ms-3">
                  <li>
                    <p>
                      <strong>Token Name:</strong> Your desired token name.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Symbol:</strong> The ticker symbol for your token
                      (e.g., ABC).
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Decimals:</strong> Commonly set to 18.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Total Supply:</strong> The total number of tokens
                      you want to create.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Social Media:</strong> Optional - Your Telegram,
                      Twitter, or Website if you have them.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Description:</strong> Add a description of your
                      token that will be visible to the public.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Distribution:</strong> Team Allocation - What
                      percentage of tokens you want to keep for yourself. The
                      rest of the tokens will be available for the public.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Select any additional features you want, such as{" "}
                  <strong>
                    Anti-bot Protection, Coin Trade Taxes, Initial Liquidity
                  </strong>
                </p>
              </li>
              <li>
                <p>Review your token details</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Pay Deployment Fee:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>
                  Ensure you have enough ETH (or the native currency of the
                  chosen blockchain, for instance ETH for Ethereum, and Base
                  network, BNB for Binance Smart Chain, MATIC for Polygon
                  network) in your wallet to cover the deployment fee.
                </p>
              </li>
              <li>
                <p>
                  Make sure you are on the right network for the type of token
                  you are deploying. (You can change the network in the upper
                  left corner of the Metamask wallet. Also, that is where you
                  can add the networks, such as Base, Binance Smart Chain,
                  Polygon, etc.)
                </p>
              </li>
              <li>
                <p>
                  Confirm the transaction in MetaMask to deploy the token
                  contract.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>4). View the Contract</strong>
        </p>
        <ul className="list-unstyled">
          <li>
            <p>
              After deployment, you will receive a contract address, displayed
              for you and emailed to the email address you provide.
            </p>
          </li>
          <li>
            <p>
              <strong>Etherscan/BscScan:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>
                  Go to{" "}
                  <a href="https://etherscan.io/" target="_blank">
                    <u>Etherscan</u>
                  </a>{" "}
                  or{" "}
                  <a href="https://bscscan.com/" target="_blank">
                    <u>BscScan</u>
                  </a>{" "}
                  (depending on your blockchain).
                </p>
              </li>
              <li>
                <p>
                  Paste your contract address in the search bar and hit enter.
                </p>
              </li>
              <li>
                <p>
                  You can view your contract details, including the total supply
                  and holders.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>5). Import Your Token into MetaMask Manually</strong>
        </p>
        <ul className="list-unstyled">
          <li>
            <p>Open MetaMask and select the account you want to use.</p>
          </li>
          <li>
            <p>Scroll down and click on "Import Tokens".</p>
          </li>
          <li>
            <p>
              <strong>Custom Token:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>Paste your token contract address.</p>
              </li>
              <li>
                <p>
                  MetaMask should automatically fill in the token symbol and
                  decimals.
                </p>
              </li>
              <li>
                <p>Click "Add Custom Token" and then "Import Tokens".</p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>6). Trade Your Token on Uniswap</strong>
        </p>
        <ul className="list-unstyled">
          <li>
            <p>
              Go to{" "}
              <a href="https://uniswap.org/" target="_blank">
                <u>Uniswap</u>
              </a>
              .
            </p>
          </li>
          <li>
            <p>Connect your MetaMask wallet.</p>
          </li>
          <li>
            <p>
              <strong>Add Token:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>
                  Click on "Select Token" and paste your token contract address
                  in the search bar.
                </p>
              </li>
              <li>
                <p>Click on "Import" to add your token.</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Trade:</strong>
            </p>
            <ul className="list-unstyled ms-3">
              <li>
                <p>
                  Select your token and the token you want to trade it with
                  (e.g., ETH).
                </p>
              </li>
              <li>
                <p>
                  Enter the amount you want to trade and confirm the
                  transaction.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>7). Send Your Token to Others</strong>
        </p>
        <ul className="list-unstyled">
          <li>
            <p>Open MetaMask and select the account holding the tokens.</p>
          </li>
          <li>
            <p>Click on "Send".</p>
          </li>
          <li>
            <p>Enter the recipient’s address.</p>
          </li>
          <li>
            <p>Enter the amount of tokens you want to send.</p>
          </li>
          <li>
            <p>Review the transaction and confirm.</p>
          </li>
        </ul>
        <p>
          <strong>
            8). Enjoy your new entertainment or collectible token on the
            Blockchain!
          </strong>
        </p>
      </div>
    </>
  );
}

export default Guide;
