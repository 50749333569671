import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./components/Main";
import Page from "./components/Page";
import Terms from "./components/Terms";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ConnectButton from "./components/ConnectButton";
import { Nav, Navbar } from "react-bootstrap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Contact from "./components/Contact";
import Guide from "./components/Guide";
import ModalConnection from "./components/web3provider";

function App() {
  const Web3Provider = ({ children }) => {
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6LcYmPEpAAAAAKx5g_0Ajkj0BlDxlIs77OxV9hSC">
        <ModalConnection>{children}</ModalConnection>
      </GoogleReCaptchaProvider>
    );
  };
  return (
    <Web3Provider>
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ fontSize: "14px", zIndex: "10" }}
        />
        <Router>
          {/* Menü veya Navigasyon Linkleri */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              justifyContent: "space-between",
              maxWidth: "100%",
            }}
          >
            <Navbar collapseOnSelect expand="md" className="p-3">
              <Navbar.Brand href="/">
                <img
                  src="logocoinbuilderpro.png"
                  alt=""
                  width="198"
                  height="36"
                  className="img-fluid"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Nav.Link href="/create-token" style={{ fontSize: "1.3rem" }}>
                    Create Token
                  </Nav.Link>
                  <Nav.Link href="/guide" style={{ fontSize: "1.3rem" }}>
                    Guide
                  </Nav.Link>

                  <ConnectButton />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/create-token" element={<Page />} />
              <Route path="/terms-of-service" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/guide" element={<Guide />} />
            </Routes>
            <div>
              <div className="page-footer"> </div>
              <div className="footer">
                <div className="container">
                  <div className="row text-center">
                    <div className="col-md-3">
                      <h5>
                        <a href="/">
                          <img
                            src="background.png"
                            alt=""
                            width="198"
                            height="36"
                            className="img-fluid"
                          />
                        </a>
                      </h5>
                    </div>
                    <div className="col-md-3">
                      <h5>
                        <a href="/create-token" className="altcizgi">
                          Token Maker
                        </a>
                      </h5>
                    </div>
                    <div className="col-md-2">
                      <h5>
                        <a href="/terms-of-service" className="altcizgi">
                          Terms of service
                        </a>
                      </h5>
                    </div>
                    <div className="col-md-2">
                      <h5>
                        <a href="/contact" className="altcizgi">
                          Contact Us
                        </a>
                      </h5>
                    </div>
                    <div className="col-md-2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          width: "26px",
                          height: "26px",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <a
                          href="http://t.me/coinbuildersupport"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fab fa-telegram"
                            style={{
                              fontSize: "32px",
                              color: "#28A4E4",
                            }}
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-12 mt-3" style={{ fontSize: "10px" }}>
                      Financial Disclaimer: CoinBuilder.pro does not offer any
                      financial advice or services. All services and EVM
                      compatible tokens created on this website are for
                      entertainment and/or collectable purposes. Users of
                      CoinBuilder.pro services are responsible for following any
                      and all laws regulating EVM compatible cryptocurrency
                      tokens in your location and jurisdiction. Please read the
                      terms of service. Have fun and enjoy your new tokens!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      </>
    </Web3Provider>
  );
}

export default App;
