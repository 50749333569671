import React, { useEffect } from "react";
import { ChainIcon } from "connectkit";
import { useAccount, useNetwork, useSwitchChain, useChainId } from "wagmi";
import { toast } from "react-toastify";
import { watchChainId } from "@wagmi/core";
import { config } from "./web3provider";

function SwitchNetwork() {
  const chainId = useChainId();
  const { chains, error, isLoading, pendingChainId, switchChain } =
    useSwitchChain();
  const { address, isConnected } = useAccount();

  const handleChange = (e) => {
    if (address && isConnected) {
      switchChain({ chainId: Number(e.target.value) });
    } else {
      toast.error("You need to connect your wallet");
    }
  };

  const handleOverlayClick = (e) => {
    if (!isConnected) {
      toast.error("You need to connect your wallet");
      e.stopPropagation();
    }
  };

  return (
    <div
      className="mb-5"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {!isConnected && (
        <div
          onClick={handleOverlayClick}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            cursor: "not-allowed",
            borderRadius: "8px",
          }}
        />
      )}
      <select
        className="form-select"
        onChange={handleChange}
        disabled={!switchChain || !isConnected}
        value={chainId || 0}
        style={{ position: "relative", zIndex: 0 }}
      >
        {chains.map((e) => (
          <option value={e.id} name={e.name} key={e.id}>
            {e.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SwitchNetwork;
