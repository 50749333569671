const networkInfo = [
  {
    id: 1,
    name: "Ethereum",
    chainId: 1,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    contractAddress: "0x7a99a09dbd2c1E33F1DA9c34d28181721801915F",
    routerAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    blockExplorer: "https://etherscan.io",
    fee: 90000000000000000,
    etherscanApiUrl: "https://api.etherscan.io/api",
    etherscanApiKey: "93JD915JB1FRZK7AUR6PHCGPX4NRFYQYZF",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
    exchangeName: "Uniswap",
    apiUrl: "https://api.coinbuilder.pro",
  },
  {
    id: 56,
    name: "BSC",
    chainId: 56,
    currencySymbol: "BNB",
    currencyName: "BNB Coin",
    contractAddress: "0xea70174455A81D897B59682b1665F356fe38f1aC",
    routerAddress: "0x10ed43c718714eb63d5aa57b78b54704e256024e",
    blockExplorer: "https://bscscan.com",
    fee: 350000000000000000,
    etherscanApiUrl: "https://api.bscscan.com/api",
    etherscanApiKey: "F9V6ZQ4WQ1S3T3MT5M5XAN2N6ZFBMNMI9F",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD",
    exchangeName: "Pancakeswap",
    apiUrl: "https://api.coinbuilder.pro",
  },
  {
    id: 250,
    name: "Fantom",
    chainId: 250,
    currencySymbol: "FTM",
    currencyName: "Fantom Opera",
    contractAddress: "0xce41360B9Ec159D3d2120BAbCc6aB6453580D857",
    routerAddress: "0x5023882f4D1EC10544FCB2066abE9C1645E95AA0",
    blockExplorer: "https://ftmscan.com",
    fee: 199000000000000000000,
    etherscanApiUrl: "https://api.ftmscan.com/api",
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiKey: "BYBEEEKCI4FKYY876AXMQSBXSMX7CNW683",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=FTM&tsyms=USD",
    exchangeName: "WigoSwap",
  },
  {
    id: 137,
    name: "Polygon",
    chainId: 137,
    currencySymbol: "MATIC",
    currencyName: "MATIC",
    contractAddress: "0xce41360B9Ec159D3d2120BAbCc6aB6453580D857",
    routerAddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    blockExplorer: "https://polygonscan.com",
    fee: 99000000000000000000,
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiUrl: "https://api.polygonscan.com/api",
    etherscanApiKey: "BSD52QMH13H395NF8P2JUJ5GKA3RKXCGEC",
    priceApi:
      "https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD",
    exchangeName: "Quickswap",
  },
  {
    id: 8453,
    name: "Base",
    chainId: 8453,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    blockExplorer: "https://basescan.org",
    contractAddress: "0xe649fec98092f17aDfF9EC7CBD2D61166558f2a3",
    routerAddress: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    fee: 90000000000000000,
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiUrl: "https://api.basescan.org/api",
    etherscanApiKey: "XJRWRIDYUN1CFR26BI9QS62IIIB28UAADX",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
  },
  {
    id: 43114,
    name: "Avalanche",
    chainId: 43114,
    currencySymbol: "AVAX",
    currencyName: "AVAX",
    blockExplorer: "https://sepolia.etherscan.io",
    contractAddress: "0xce41360B9Ec159D3d2120BAbCc6aB6453580D857",
    routerAddress: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
    fee: 90000000000000000,
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiUrl:
      "https://api.routescan.io/v2/network/mainnet/evm/43114/etherscan/api",
    etherscanApiKey: "",
    priceApi:
      "https://min-api.cryptocompare.com/data/price?fsym=AVAX&tsyms=USD",
  },
  {
    id: 42161,
    name: "Arbitrum",
    chainId: 42161,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    blockExplorer: "https://arbiscan.io",
    contractAddress: "0xce41360B9Ec159D3d2120BAbCc6aB6453580D857",
    routerAddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    fee: 90000000000000000,
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiUrl: "https://api.arbiscan.io/api",
    etherscanApiKey: "TYNN332SUEVHGNUW8KAZASZV1XZ7H9BDG1",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
  },
  {
    id: 10,
    name: "Optimism",
    chainId: 10,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    blockExplorer: "https://optimistic.etherscan.io",
    contractAddress: "0xd0C9e1ea29769b07213b3de7cDAB980f015cC989",
    routerAddress: "0x4A7b5Da61326A6379179b40d00F57E5bbDC962c2",
    fee: 90000000000000000,
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiUrl: "https://api-optimistic.etherscan.io/api",
    etherscanApiKey: "UZ2HNUXJN42YHTPF3RCUQUBCK54NXISA9W",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
  },
  {
    id: 11155111,
    name: "Sepolia",
    chainId: 11155111,
    currencySymbol: "sETH",
    currencyName: "Ethereum",
    blockExplorer: "https://sepolia.etherscan.io",
    contractAddress: "0xEd465523bb446F436772425CF39c96778E8B46b2",
    routerAddress: "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008",
    fee: 90000000000000000,
    apiUrl: "https://api.coinbuilder.pro",
    etherscanApiUrl: "https://api-sepolia.etherscan.io/api",
    etherscanApiKey: "93JD915JB1FRZK7AUR6PHCGPX4NRFYQYZF",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
  },
  {
    id: 97,
    name: "BSC Testnet",
    chainId: 97,
    currencySymbol: "tBNB",
    currencyName: "BNB Coin",
    contractAddress: "0xB5eD45d99F7AFF47b946212F94D856a6106B11A8",
    routerAddress: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
    blockExplorer: "https://testnet.bscscan.com",
    fee: 350000000000000000,
    etherscanApiUrl: "https://api-testnet.bscscan.com/api",
    etherscanApiKey: "F9V6ZQ4WQ1S3T3MT5M5XAN2N6ZFBMNMI9F",
    priceApi: "https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD",
    exchangeName: "Pancakeswap",
    apiUrl: "https://api.coinbuilder.pro",
  },
];

export default networkInfo;
