import React from "react";

function ConnectButton() {
  function truncateEthereumAddress(address) {
    const truncatedAddress =
      address.substring(0, 6) + "..." + address.substring(address.length - 4);
    return truncatedAddress;
  }
  return (
    <div className="mt-1">
      <w3m-button balance="hide" />
    </div>
  );
}

export default ConnectButton;
