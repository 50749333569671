import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createWeb3Modal } from "@web3modal/wagmi/react";

import { http, createConfig, WagmiProvider } from "wagmi";
import {
  mainnet,
  bsc,
  fantom,
  polygon,
  base,
  optimism,
  arbitrum,
  avalanche,
  bscTestnet,
} from "wagmi/chains";
import {
  walletConnect,
  coinbaseWallet,
  injected,
  metaMask,
} from "wagmi/connectors";
import { watchChainId } from "@wagmi/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

// Required API Keys
//alchemyId: "2y36ih4AWP8zYI9zAV-31vP-K8yZqQia", // or infuraId
const projectId = "734adb9155ad6e52e3c09a08880f1bb7";

const metadata = {
  name: "Coinbuilder.pro",
  description:
    "CoinBuilder.pro is an easy to use and cost effective way to create a new meme coin or any new entertainment or collectable crypto coin on Ethereum compatible blockchains.",
  // url: "https://coinbulder.pro", // origin must match your domain & subdomain
  icons: ["https://coinbuilder.pro/favicon.webp"],
};

const chains = [
  mainnet,
  bsc,
  fantom,
  polygon,
  base,
  optimism,
  arbitrum,
  avalanche,
  bscTestnet,
];

// create the connectors
const connectors = [
  walletConnect({ projectId }),
  injected(),
  metaMask(),
  coinbaseWallet(),
];

const wagmiConfig = createConfig({
  chains, // Use the defined chains here
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: http(),
    [fantom.id]: http(),
    [polygon.id]: http(),
    [base.id]: http(),
    [optimism.id]: http(),
    [avalanche.id]: http(),
    [arbitrum.id]: http(),
    [bscTestnet.id]: http(),
  },
  connectors: connectors,
});

export const config = wagmiConfig;

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, themeMode: "light" });

const ModalConnection = ({ children }) => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

export default ModalConnection;
