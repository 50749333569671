import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import ConnectButton from "./ConnectButton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper";

function App() {
  const imageUrls = [
    { name: "Ethereum", url: "assets/images/ethereum.svg" },
    { name: "Avalanche", url: "assets/images/avalanche.svg" },
    { name: "Arbitrum ", url: "assets/images/arbitrum.svg" },
    { name: "Fantom", url: "assets/images/fantom.svg" },
    { name: "Optimism", url: "assets/images/optimism.svg" },
    { name: "BSC", url: "assets/images/bsc.svg" },
    { name: "Polygon", url: "assets/images/polygon.svg" },
    { name: "Base", url: "assets/images/base.svg" },
  ];

  const images1 = [
    "0d7938e1-9b3b-4d8b-177b-98188c4cf400.webp",
    "1bf33a89-b049-4a1c-d1f6-4dd7419ee400.webp",
    "204b2240-5ce4-4996-6ec4-f06a22726900.webp",
    "20c3072e-c92e-4902-d4b9-cb2b6ab29100.webp",
    "215158d2-614b-49c9-410f-77aa661c3900.webp",
    "252753e7-b783-4e03-7f77-d39864530900.webp",
    "26a8f588-3231-4411-60ce-5bb6b805a700.webp",
    "2cd67b4c-282b-4809-e7c0-a88cd5116f00.webp",
    "3913df81-63c2-4413-d60b-8ff83cbed500.webp",
    "45f2f08e-fc0c-4d62-3e63-404e72170500.webp",
    "4c16cad4-cac9-4643-6726-c696efaf5200.webp",
    "5195e9db-94d8-4579-6f11-ef553be95100.webp",
    "52b1da3c-9e72-40ae-5dac-6142addd9c00.webp",
    "5b1cddfb-056e-4e78-029a-54de5d70c500.webp",
    "6133c399-ae32-4eba-0c5a-0fb84492bf00.webp",
    "68e8063a-ff69-4941-3b40-af09e2fcd700.webp",
    "6f913b80-86c0-46f9-61ca-cc90a1805900.webp",
    "73f6f52f-7862-49e7-bb85-ba93ab72cc00.webp",
    "7677b54f-3486-46e2-4e37-bf8747814f00.webp",
  ];

  const images2 = [
    "7a33d7f1-3d12-4b5c-f3ee-5cd83cb1b500.webp",
    "7c5ff577-a68d-49c5-02cd-3d83637b0b00.webp",
    "7e1514ba-932d-415d-1bdb-bccb6c2cbc00.webp",
    "877fa1a4-304d-4d45-ca8e-f76d1a556f00.webp",
    "99520548-525c-49d7-fb2f-5db65293b000.webp",
    "9f259366-0bcd-4817-0af9-f78773e41900.webp",
    "a78c4d48-32c1-4a9d-52f2-ec7ee08ce200.webp",
    "a7f416de-aa03-4c5e-3280-ab49269aef00.webp",
    "b6ee4efc-f53e-475b-927b-a7ded6211700.webp",
    "bff9cf1f-df19-42ce-f62a-87f04df13c00.webp",
    "c39b3a16-1a38-4588-f089-cb7aeb584700.webp",
    "d22b2a4b-5562-49ba-506b-6d5986914600.webp",
    "dda0f0fb-34e8-4a57-dcea-b008e7d1ff00.webp",
    "dfe0e3e3-5746-4e2b-12ad-704608531500.webp",
    "e2024511-2c9b-46d7-3111-52df3d241700.webp",
    "ebac7b39-688c-41e3-7912-a4fefba74600.webp",
    "f3119826-4ef5-4d31-4789-d4ae5c18e400.webp",
    "f78dab27-7165-4a3d-fdb1-fcff06c0a700.webp",
    "f9854c79-14ba-4987-42e1-4a82abbf5700.webp",
    "fc460647-ea95-447a-99f0-1bff8fa4be00.webp",
    "fe1b9394-55af-4828-a70d-5c5b7de6b200.webp",
  ];
  SwiperCore.use([Autoplay]);
  return (
    <>
      <div className="banner">
        <div className="container kenar">
          <h1 className="renk">
            <span className="flash">
              Easy Token Generator – Crypto Coin Maker
            </span>
          </h1>
          <br />
          <br />
          <h5>
            CoinBuilder.pro is an easy to use and cost effective way to create a
            new meme coin or any new entertainment or collectable crypto coin on
            Ethereum compatible blockchains.
          </h5>
          <h5>
            We got you covered! Our Token Maker will help you generate a
            token/coin automatically, and deploy it in a matter of minutes.
          </h5>{" "}
          <br />
          <br />
          <div className="text-center">
            <button className="btn-token">
              <a
                href="/create-token"
                style={{ textDecoration: "none", color: "white" }}
              >
                Create Your Token
              </a>
            </button>{" "}
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12">
            <h1>Create your token/coin in just a few easy steps:</h1>
          </div>

          <div className="col-md-6">
            <div>
              <div className="text-center">
                <a href="https://metamask.io/" target="_blank">
                  <img src="/assets/new-images/step1.png" alt="" />
                </a>
              </div>
              <h5 className="text-center" style={{ color: "#24A55F" }}>
                {" "}
                <b> 1. Install MetasMask </b>{" "}
              </h5>
              <p>
                If you don't have a cryptocurrency wallet, please make sure to{" "}
                <a href="https://metamask.io/" target="_blank">
                  install MetaMask
                </a>{" "}
                or any of the supported wallets, such as:{" "}
                <a
                  href="https://www.coinbase.com/wallet/articles/getting-started-extension"
                  target="_blank"
                >
                  Coinbase wallet
                </a>
                ,{" "}
                <a
                  href="https://trustwallet.com/browser-extension"
                  target="_blank"
                >
                  Trust wallet
                </a>
                , or{" "}
                <a href="https://brave.com/wallet/" target="_blank">
                  Brave wallet
                </a>
                . These are crypto wallets you add to your internet browser, or
                for your mobile devices.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div className="text-center">
                <a href="/create-token">
                  <img src="/assets/new-images/step2.png" alt="" />
                </a>
              </div>
              <h5 className="text-center" style={{ color: "#24A55F" }}>
                <b> 2. Deposit cryptocurrency in your wallet</b>
              </h5>
              <p>
                Make sure to have enough cryptocurrency (Ethereum) available to
                pay for the Smart Contract deployment. The amount varies based
                on the blockchain you select.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center">
              <a href="/create-token">
                <img src="/assets/new-images/step3.png" alt="" />
              </a>
            </div>{" "}
            <h5 className="text-center" style={{ color: "#24A55F" }}>
              {" "}
              <b> 3. Fill-out Crypto Token details </b>
            </h5>
            <p>
              Fill the basic information for your new token/coin like the name,
              and symbol of your token. Add advanced features like the taxes and
              recipients. Whether it is a new meme coin or a utility crypto
              coin, you can create them here!
            </p>
          </div>

          <div className="col-md-6">
            <div className="text-center">
              <a href="/create-token">
                <img src="/assets/new-images/step4.png" alt="" />
              </a>
            </div>{" "}
            <h5 className="text-center" style={{ color: "#24A55F" }}>
              {" "}
              <b> 4. Deploy your Token </b>
            </h5>
            <p>
              That's it, you're good to go! Confirm the transaction on MetaMask
              and your Token will be deployed in a matter of seconds!
            </p>
          </div>
        </div>
      </div>
      <div className="ready container-fluid">
        <h3>Launch Your Cryptocurrency Token Today!</h3> <br />
        <p>
          Discover our state-of-the-art token generator app, designed for fast
          and secure minting of EVM-compatible tokens on popular Layer 2
          networks. Our platform offers a streamlined process, integrating
          seamlessly with WalletConnect for enhanced security and ease of use.
          Start your journey in the crypto space, deploy your digital asset
          effortlessly. Unlock your token’s potential now!
        </p>
        <button className="btn-start">
          {" "}
          <a
            href="/create-token"
            style={{ textDecoration: "none", color: "white" }}
          >
            {" "}
            Start Now
          </a>
        </button>
      </div>
      <div className="container mt-3 mb-5">
        <div className="row">
          <h3 className="text-center mt-5">
            Features of Our Advanced Token Builder:
          </h3>
        </div>

        <div className="row mt-5">
          <div className="col-3 col-md-2">
            <img src="/assets/new-images/features2.png" alt="" />
          </div>
          <div className="col-9 col-md-10">
            <h6>ERC20/BEP20 Standard Compliance: </h6>
            <p>
              Ensure your cryptocurrency token is fully compliant with
              ERC20/BEP20 standards, guaranteeing compatibility across all
              digital wallets globally. Each token features a unique name,
              symbol, and precision in decimals, enhancing its recognition and
              usability worldwide.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-3 col-md-2">
            <img src="/assets/new-images/features1.png" alt="" />
          </div>
          <div className="col-9 col-md-10">
            <h6>Automated Source Code Verification:</h6>
            <p>
              We streamline your launch process by automatically publishing and
              verifying your contract’s source code, boosting transparency and
              trust among users.
            </p>
          </div>
        </div>

        {/*  <div className="row mt-5">
            <div className="col-3 col-md-2">
              <img src="/assets/new-images/features3.png" alt="" />
            </div>
            <div className="col-9 col-md-10">
              <h6>Burnable Tokens:</h6>
              <p>
                Opt for a burnable token feature, allowing you to reduce the
                supply and potentially increase the value of your asset
                strategically.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-3 col-md-2">
              <img src="/assets/new-images/features4.png" alt="" />
            </div>
            <div className="col-9 col-md-10">
              <h6>Mintable Tokens:</h6>
              <p>
                Flexibility is key; select our mintable option to create
                additional tokens post-launch, perfect for scaling your project
                as demand grows.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-3 col-md-2">
              <img src="/assets/new-images/features5.png" alt="" />
            </div>
            <div className="col-9 col-md-10">
              <h6>Pausable Transactions:</h6>
              <p>
                Utilize the pause function to control when your tokens can be
                traded, providing you with leverage over token circulation until
                your specified date.
              </p>
            </div>
          </div>*/}

        <div className="row mt-5">
          <div className="col-3 col-md-2">
            <img src="/assets/new-images/features6.png" alt="" />
          </div>
          <div className="col-9 col-md-10">
            <h6>Exclusive Ownership: </h6>
            <p>
              Take complete control of your token and ownership features,
              enabling you to manage tax processes and access securely.
            </p>
          </div>
        </div>

        {/* 
          <div className="row mt-5">
            <div className="col-3 col-md-2">
              <img src="/assets/new-images/features7.png" alt="" />
            </div>
            <div className="col-9 col-md-10">
              <h6>Supply Control Options:</h6>
              <p>
                {" "}
                Choose from fixed, capped, or unlimited supply options to tailor
                the scarcity and distribution of your token according to your
                project’s needs.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-3 col-md-2">
              <img src="/assets/new-images/features8.png" alt="" />
            </div>
            <div className="col-9 col-md-10">
              <h6>Token Recovery Feature: </h6>
              <p>
                Safeguard your tokens with our innovative recovery feature,
                which addresses and resolves accidental losses by retrieving
                tokens mistakenly sent to smart contracts.
              </p>
            </div>
          </div>
          */}

        <div className="row mt-5">
          <div className="col-3 col-md-2">
            <img src="/assets/new-images/features9.png" alt="" />
          </div>
          <div className="col-9 col-md-10">
            <h6>Anti-Whale Mechanisms:</h6>
            <p>
              {" "}
              Implement limits on the amount of tokens one wallet can hold to
              prevent market manipulation and ensure a more equitable
              distribution of tokens.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-3 col-md-2">
            <img src="/assets/new-images/features10.png" alt="" />
          </div>
          <div className="col-9 col-md-10">
            <h6>Transaction Taxes: </h6>
            <p>
              Establish a transaction tax to fund ongoing development and team
              support, aiding in sustainable project growth.
            </p>
          </div>
        </div>
        <div className="row text-center mt-3 mb-3 p-3">
          <h3>Blockchains</h3>

          <p>
            Discover the power of our cutting-edge token generator, enabling
            seamless token creation across multiple leading blockchains
            including Ethereum, Base, Binance Smart Chain, Polygon, Avalanche,
            Fantom, Arbitrum, and Optimism. Experience rapid deployment and
            on-chain validation of your token (meme coin or other any other
            coin) in mere seconds, all directly from your crypto wallet.
            Customize advanced configurations, ensuring it perfectly aligns with
            your unique requirements. Unleash the potential of your digital
            assets with ease and efficiency.
          </p>
        </div>
        <div className="row text-center mb-5">
          {imageUrls.map((e) => (
            <div className="col-md-3 mt-3 mb-3" key={e.url}>
              <a className="blockchain-icon" href="/create-token">
                <img
                  src={e.url}
                  alt="Blockchain Icon"
                  style={{ width: "200px", height: "200px" }}
                />
              </a>
              <h5 className="mt-2">{e.name}</h5>
            </div>
          ))}
        </div>
      </div>

      <div className="contact">
        <h3>Create your own cryptocurrency token today!</h3> <br />
        <h5>
          Connect your wallet now and create your own cryptocurrency token!
        </h5>{" "}
        <br />
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <ConnectButton />
        </div>
      </div>
      <div className="faq">
        <div className="row mt-3 mb-5">
          <div className="col-md-12 mt-3 mb-5 text-center">
            <h3>Supported Wallets</h3>
          </div>
          <Swiper
            allowTouchMove={false}
            spaceBetween={5}
            breakpoints={{
              320: {
                slidesPerView: 3,
              },
              480: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 6,
              },
              992: {
                slidesPerView: 8,
              },
              1200: {
                slidesPerView: 9,
              },
              1920: {
                slidesPerView: 10,
              },
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={2000}
          >
            {images1.map((e) => (
              <SwiperSlide key={e}>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: "50%",
                    overFlow: "hidden",
                  }}
                >
                  <img
                    src={require(`../assets/images/wallets/${e}`)}
                    width="100"
                    style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: "50%",
                      overFlow: "hidden",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-2 mb-2"></div>
          <Swiper
            spaceBetween={5}
            allowTouchMove={false}
            breakpoints={{
              320: {
                slidesPerView: 3,
              },
              480: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 6,
              },
              992: {
                slidesPerView: 8,
              },
              1200: {
                slidesPerView: 9,
              },
              1920: {
                slidesPerView: 10,
              },
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              reverseDirection: true,
            }}
            speed={2000}
          >
            {images2.map((e) => (
              <SwiperSlide key={e}>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: "50%",
                    overFlow: "hidden",
                  }}
                >
                  <img
                    src={require(`../assets/images/wallets/${e}`)}
                    width="100"
                    style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: "50%",
                      overFlow: "hidden",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="text-center">
          <h2>FAQ</h2>
        </div>

        <div className="akordion">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is an ERC20 Token?</Accordion.Header>
              <Accordion.Body>
                The ERC-20 introduces a standard for Fungible Tokens, which
                means that each Token has a property that makes it identical to
                another Token (in terms of type and value). An ERC-20 Token, for
                example, functions similarly to ETH, meaning that one Token is
                and will always be equal to all other Tokens.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                What is a BEP20 Token standard?
              </Accordion.Header>
              <Accordion.Body>
                It is a native token standard of the Binance Smart Chain. It
                acts as a blueprint of how the BEP-20 tokens can be utilized. It
                is an extension of the ERC-20 token standard and can be used to
                represent shares or fiat.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>What is Fixed Supply Token?</Accordion.Header>
              <Accordion.Body>
                Once you deploy the token, the entire supply will be sent to the
                owner's wallet. Fixed supply means that this supply can't be
                modified later on.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>What is Capped Supply Token?</Accordion.Header>
              <Accordion.Body>
                When you create the token, you will have the option to choose to
                send an initial supply to the owner's wallet. Supply can be
                adjusted later on by minting or burning tokens if you chose
                those options. You won't be able to generate more tokens that
                supply cap allows.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                How is the smart contract's source code verified?
              </Accordion.Header>
              <Accordion.Body>
                When we deploy your smart contract, a third party such as
                Etherscan verifies the source code and publishes it on their
                website. The source code can be found on the contract's webpage.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                What kind of access type can I set up?
              </Accordion.Header>
              <Accordion.Body>
                None: there is no central authority which can make people trust
                your token more Ownable: The token will have an owner who will
                act as admin and be able to conduct different actions such as
                mining, burning... Role based: You will be able to assign
                different users to different tasks such as mining, burning...
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>How can those tokens be used?</Accordion.Header>
              <Accordion.Body>
                The tokens we will generate for you can be used for a wide range
                of applications. As a crypto currency, to run an ICO, as proof
                of ownership (i.e. shares in a company), for gaming, charity,
                loyalty program, you name it!{" "}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>Who owns generated tokens?</Accordion.Header>
              <Accordion.Body>
                You will be the sole owner of the tokens except if you decide to
                make somebody else the owner
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>
                What kind of tokens are used for ICOs?
              </Accordion.Header>
              <Accordion.Body>
                Your tokens generated on this website will meet all the
                requirements to be used for an ICO
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>
                How to Create an ERC20 or BEP20 token?
              </Accordion.Header>
              <Accordion.Body>
                If you want to create an ERC20 token the easieast solution is to
                do it automatically with our Token Maker. This is also a very
                inexpensive token maker, and one of the cheapest ways to create
                a new cryptocurrency.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>
                Where can I buy or sell my Tokens after created?
              </Accordion.Header>
              <Accordion.Body>
                To make your token tradable, you will need to add liquidity on a
                DEX such as Uniswap or Pancaswap. Once the liquidity is added,
                anyone will be able to buy and sell your token instantly.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="text-center mt-5 mb-5">
        <button className="btn-deploy">
          <a
            href="/create-token"
            style={{ textDecoration: "none", color: "white" }}
          >
            {" "}
            Deploy Your Token{" "}
          </a>
        </button>
        <br />
      </div>
    </>
  );
}

export default App;
