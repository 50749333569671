import React, { useState } from "react";

function InfoInput({ link, label, onChange, name, value, disabled, optional }) {
  const [focused, setFocused] = useState(false);

  return (
    <>
      {label && <label className="mt-4">{label}</label>}
      {<span style={{ color: "gray" }}>{optional && " Optional"}</span>}

      <div
        className="col-12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <p style={{ margin: 0, textAlign: "right" }}>{link}</p>
        <input
          type="text"
          className="form-control"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          autoComplete="off"
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        />
      </div>
    </>
  );
}

export default InfoInput;
