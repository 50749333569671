import React from "react";
import { useState } from "react";

function Input({
  label,
  placeholder,
  required,
  onChange,
  name,
  value,
  disabled,
  optional,
  type,
  onFocus,
  onBlur,
}) {
  return (
    <div className="col">
      {label && (
        <label>
          {label}
          <span style={{ color: "red" }}>{required && " *"}</span>
          {optional && <span style={{ color: "lightgray" }}>{optional}</span>}
        </label>
      )}
      <input
        type={type ? type : "text"}
        className="form-control"
        autoComplete="off"
        placeholder={placeholder && placeholder}
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
        onFocus={onFocus && onFocus}
        onBlur={onBlur && onBlur}
      />
    </div>
  );
}

export default Input;
