import SwitchNetwork from "./SwitchNetwork";
import React, { useEffect } from "react";
import {
  useAccount,
  useWriteContract,
  useChainId,
  useWaitForTransactionReceipt,
  useReadContract,
  useWalletClient,
  usePublicClient,
} from "wagmi";
import InfoInput from "./InfoInput";
import Input from "./Input";
import { useState } from "react";
import axios from "axios";
import { abi1 } from "./abi/factoryContract";
import info from "../info";
import { multicall, writeContract } from "@wagmi/core";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  addDecimal,
  encodeConstructorArguments,
  getCode,
  removeDecimal,
  verifyContract,
} from "./helpers";
import PageTitle from "./PageTitle";
import { toast } from "react-toastify";
import networkInfo from "../networks";
import { erc20Abi } from "viem";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import { config } from "./web3provider.js";
import ConnectButton from "./ConnectButton";

const Page = () => {
  const [writeError, setwriteError] = useState(null);
  const [hash, sethash] = useState(null);
  const { address, isConnected } = useAccount();
  const [screen, setscreen] = useState(0);
  const [deploymentStatus, setDeploymentStatus] = useState(
    "Transaction starting, Confirm transaction in your wallet, when it requests."
  );
  const chainId = useChainId();
  const [mail, setmail] = useState("");
  const [deploymentLoading, setDeploymentLoading] = useState(true);
  const [deploymentDesc, setDeploymentDesc] = useState("");
  const { data: wClient } = useWalletClient();
  const [currentNetwork, setCurrentNetwork] = useState(null);

  useEffect(() => {
    if (address && isConnected) {
      const net = networkInfo.filter((e) => e.id == chainId)[0];
      setCurrentNetwork(net);
    }
  }, [address, isConnected, chainId]);

  useEffect(() => {
    if (writeError) {
      setDeploymentStatus(writeError);
      setDeploymentLoading(false);
    }
  }, [writeError]);

  useEffect(() => {
    if (hash) {
      setDeploymentStatus("Transaction sent");
      setDeploymentDesc("Please wait for result.");
      setDeploymentLoading(true);
    }
  }, [hash]);

  //Distribution akordiyon
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const [terms, setterms] = useState(false);

  // Anti-bot protection akordiyon

  const [isLimitPerWalletOpen, setIsLimitPerWalletOpen] = useState(false);
  const [isLimitPerTransactionOpen, setIsLimitPerTransactionOpen] =
    useState(false);
  const toggleLimitPerWalletAccordion = () => {
    setIsLimitPerWalletOpen(!isLimitPerWalletOpen);
  };

  const toggleLimitPerTransactionAccordion = () => {
    setIsLimitPerTransactionOpen(!isLimitPerTransactionOpen);
  };

  //tax Akordiyon

  const [isBuyTaxOpen, setIsBuyTaxOpen] = useState(false);

  const toggleBuyTaxAccordion = () => {
    setIsBuyTaxOpen(!isBuyTaxOpen);
  };

  const [isSellTaxOpen, setIsSellTaxOpen] = useState(false);

  const toggleSellTaxAccordion = () => {
    setIsSellTaxOpen(!isSellTaxOpen);
  };

  const [isUniswapOpen, setIsUniswapOpen] = useState(false);

  const toggleIsUniswapAccordion = () => {
    setIsUniswapOpen(!isUniswapOpen);
  };

  const [isBurnOpen, setIsBurnOpen] = useState(false);

  const toggleIsBurnAccordion = () => {
    setIsBurnOpen(!isBurnOpen);
  };

  //sidebar sayfalar ileri geri

  const handleNext = () => {
    let arr = [];
    if (
      !data.tokenname ||
      !data.tokensymbol ||
      !data.totalsupply ||
      (isOpen && !data.teamAddress) ||
      ((isSellTaxOpen || isBuyTaxOpen) && !data.taxWallet) ||
      (isUniswapOpen && !Number(data.liquidityEth)) ||
      !terms
    ) {
      if (!data.tokenname) {
        arr.push("Token name is required ");
      }
      if (!data.tokensymbol) {
        arr.push("Token symbol is required ");
      }
      if (!data.totalsupply) {
        arr.push("Total supply is required ");
      }
      if (isOpen && !data.teamAddress) {
        arr.push("Team address is required ");
      }
      if ((isSellTaxOpen || isBuyTaxOpen) && !data.taxWallet) {
        arr.push("Tax wallet is required ");
      }
      if (isUniswapOpen && !Number(data.liquidityEth)) {
        arr.push("Liquidity ETH amount must be provided ");
      }
      if (!terms) {
        arr.push("You must accept the terms ");
      }
      toast.error(arr.toString());
    } else {
      deploy();
    }
  };

  //------------------------input yönetimi-----------------

  //token info sayfası
  const [data, setData] = useState({
    tokenname: "",
    tokensymbol: "",
    totalsupply: "",
    telegram: "",
    twitter: "",
    website: "",
    description: "",
    teamPercent: "",
    teamAddress: "",
    maxWallet: "",
    maxTx: "",
    buyTax: "",
    sellTax: "",
    taxWallet: "",
    liquidityEth: "",
  });

  const handleChange = (e) => {
    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const [tokensToLiq, setTokensToLiq] = useState("0");
  const [tokensToOwner, setTokensToOwner] = useState("0");
  const [tokensToTeam, setTokensToTeam] = useState("0");
  useEffect(() => {
    const getTokensToLiq = () => {
      if (data.totalsupply && data.liquidityEth && isUniswapOpen) {
        if (Number(data.teamPercent) > 0 && isOpen) {
          setTokensToLiq(
            (Number(data.totalsupply) / 100) * (100 - Number(data.teamPercent))
          );
        } else {
          setTokensToLiq(data.totalsupply);
        }
      } else {
        setTokensToLiq(0);
      }
    };

    getTokensToLiq();
  }, [data, isOpen]);

  const { data: feeData, error: feeError } = useReadContract({
    abi: abi1,
    address: currentNetwork && currentNetwork.contractAddress,
    functionName: "fee",
  });

  useEffect(() => {
    console.log(feeData);
    console.log(feeError);
  }, [feeData, feeError]);

  const [ethPrice, setEthPrice] = useState(null);

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const url = currentNetwork?.priceApi || networkInfo[0].priceApi;
        const res = await axios.get(url);
        setEthPrice(res.data.USD);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEthPrice();
  }, [currentNetwork]);

  const [usdMCap, setUsdMCap] = useState("");

  useEffect(() => {
    calculateDistribution();
    if (ethPrice && data.liquidityEth) {
      if (data.teamPercent && isOpen) {
        const currentCap = ethPrice * Number(data.liquidityEth);
        const hundredPercent = (currentCap / (100 - data.teamPercent)) * 100;
        setUsdMCap(hundredPercent.toFixed(4));
      } else {
        setUsdMCap(ethPrice * Number(data.liquidityEth).toFixed(4));
      }
    }
  }, [ethPrice, data, isOpen]);

  const calculateDistribution = () => {
    if (isOpen && isUniswapOpen) {
      if (data.teamPercent && data.totalsupply) {
        const team =
          (Number(data.totalsupply) / 100) * Number(data.teamPercent);
        setTokensToTeam(team.toString());
        setTokensToOwner("0");
      }
    } else if (!isOpen && !isUniswapOpen) {
      if (data.totalsupply) {
        setTokensToOwner(data.totalsupply);
        setTokensToTeam("0");
      }
    } else if (isOpen && !isUniswapOpen) {
      if (data.teamPercent && data.totalsupply) {
        const team =
          (Number(data.totalsupply) / 100) * Number(data.teamPercent);
        setTokensToTeam(team.toString());
        const owner = Number(data.totalsupply) - team;
        setTokensToOwner(owner);
      }
    } else if (!isOpen && isUniswapOpen) {
      if (data.totalsupply) {
        setTokensToOwner("0");
        setTokensToTeam("0");
      }
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [constructorArgs, setConstructorArgs] = useState("");
  const [sourceCode, setSourceCode] = useState("");
  const [tokenname, settokenname] = useState("");

  const deploy = async () => {
    settokenname(data.tokenname);
    setscreen((pre) => pre + 1);
    const sourc = getCode(data);
    setSourceCode(sourc);
    const getByte = async (src) => {
      const res = await axios.post(info.apiUrl, { contract: src });
      console.log(res);
      console.log("bytecode", res.data.data.contracts, "source code", sourc);

      return res.data.data.contracts["token.sol"].TronTrump.evm.bytecode.object;
    };

    const bytecode = await getByte(sourc);

    const teamWallet = isOpen ? data.teamAddress : address;
    const txLimit = isLimitPerTransactionOpen
      ? (Number(data.totalsupply) / 100) * Number(data.maxTx)
      : data.totalsupply;
    const walletLimit = isLimitPerWalletOpen
      ? (Number(data.totalsupply) / 100) * Number(data.maxWallet)
      : data.totalsupply;
    const buyTax = isBuyTaxOpen ? data.buyTax : "0";
    const sellTax = isSellTaxOpen ? data.sellTax : "0";
    const taxWallet = data.taxWallet ? data.taxWallet : address;

    const liqBurn = () => {
      if (isUniswapOpen && isBurnOpen) {
        return true;
      } else {
        return false;
      }
    };

    const liqEth = () => {
      if (isUniswapOpen && Number(data.liquidityEth) > 0) {
        return addDecimal(data.liquidityEth, "18");
      } else {
        return "0";
      }
    };

    const liqTokens = () => {
      if (isUniswapOpen && Number(tokensToLiq)) {
        return addDecimal(tokensToLiq, "18");
      } else {
        return "0";
      }
    };

    const tokensOwner = () => {
      if (Number(tokensToOwner) > 0) {
        return addDecimal(tokensToOwner, "18");
      } else {
        return "0";
      }
    };

    const ethToLiq = liqEth();
    const tokensLiq = liqTokens();
    const ownerTokens = tokensOwner();
    const liqBurning = liqBurn();

    const addresses = [
      taxWallet,
      currentNetwork.routerAddress,
      address,
      teamWallet,
    ];
    const amounts = [
      addDecimal(tokensToTeam, "18"),
      addDecimal(txLimit, "18"),
      addDecimal(walletLimit, "18"),
      buyTax,
      sellTax,
      ethToLiq,
      tokensLiq,
      ownerTokens,
    ];
    const value = () => {
      if (isUniswapOpen && data.liquidityEth > 0) {
        return String(
          Number(addDecimal(data.liquidityEth, "18")) +
            Number(feeData.toString())
        );
      } else {
        return feeData.toString();
      }
    };

    const val = value();

    const byt = "0x" + bytecode;

    const args = [
      data.tokenname,
      data.tokensymbol,
      "18",
      addresses,
      amounts,
      liqBurning,
      byt,
    ];
    console.log(args, address, val);
    try {
      const hashh = await wClient.writeContract({
        address: currentNetwork?.contractAddress,
        abi: abi1,
        functionName: "createNewCoin",
        args: args,
        from: address,
        value: val,
      });

      sethash(hashh);
      const constargs = encodeConstructorArguments(
        data.tokenname,
        data.tokensymbol,
        "18",
        addresses,
        amounts,
        currentNetwork.contractAddress
      );
      setConstructorArgs(constargs);
    } catch (error) {
      console.log(error);
      setwriteError(error.message);
    }
  };

  const [deployedTokenAddress, setDeployedTokenAddress] = useState("");

  const getTokens = async (contracts) => {
    const res = await multicall(config, { contracts });
    return res;
  };

  const {
    data: deployed,
    isLoading: isConfirming,
    isSuccess: isConfirmed,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    const run = async () => {
      if (isConfirmed) {
        console.log(deployed);
        const contracts = deployed.logs.map((e) => ({
          address: e.address,
          abi: erc20Abi,
          functionName: "name",
        }));
        console.log(contracts);
        const handleVerifyAndMail = async (f) => {
          var res = f.filter((e) => e.result == tokenname);
          setDeployedTokenAddress(contracts[f.indexOf(res[0])].address);
          verifyContract(
            contracts[f.indexOf(res[0])].address,
            sourceCode,
            constructorArgs,
            setDeploymentStatus,
            setDeploymentLoading,
            setDeploymentDesc,
            currentNetwork
          );
          const name = "New Sale";
          const email = ` on ${currentNetwork.name}`;
          const message = `New transaction happened  by ${address} contract address ${
            currentNetwork.contractAddress
          } mail address of user ${mail ? mail : "empty"}`;

          const message2 = mail
            ? `Thank you for using Coinbuilder.pro Here is your contract addres. 
        ${currentNetwork.blockExplorer}/address/${
                contracts[f.indexOf(res[0])].address
              }`
            : "";

          await notifyWithMail(name, email, message, mail, message2);
        };
        getTokens(contracts).then((f) => {
          handleVerifyAndMail(f);
        });

        setDeploymentStatus(`Your Transaction has been confirmed.`);
        setDeploymentDesc(
          "Please wait for source code verification. Do not close window!!!!"
        );
        setDeploymentLoading(true);
      }
    };
    run();
  }, [isConfirmed]);

  const notifyWithMail = async (name, email, message, email2, message2) => {
    try {
      const response = await axios.post(info.apiUrl + "/created", {
        name: name,
        email: email,
        message: message,
        email2: email2,
        message2: message2,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="banner">
        <div className="container kenar">
          <h1 className="renk">
            <span className="flash">Create Your Token</span>
          </h1>
          <br />
          <br />
          <h5>Easily deploy your Smart Contract without coding knowledge.</h5>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="wrapper">
            <div className="shape"></div>
            <div className="container">
              <div className="row"></div>
            </div>
            <section className="bloglist">
              <div className="container">
                <div className="row">
                  {screen == 1 && (
                    <PageTitle
                      statusText={deploymentStatus}
                      processName={deploymentDesc}
                      deploymentLoading={deploymentLoading}
                      deployedTokenAddress={
                        deployedTokenAddress && deployedTokenAddress
                      }
                      currentNetwork={currentNetwork}
                    />
                  )}
                  {screen == 0 && (
                    <>
                      <div className="col-md-4">
                        <div className="card" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              {" "}
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">General Information</h5>
                                  <p>Set up basic information.</p>
                                </div>
                                <div className="line"></div>
                              </div>
                              <Input
                                label={"Token Name "}
                                placeholder={"Name of your token "}
                                required={true}
                                name="tokenname"
                                onChange={handleChange}
                                value={data.tokenname}
                              />
                              <div className="mt-3">
                                <Input
                                  placeholder="Symbol of your token"
                                  required={true}
                                  label={<>Token Symbol</>}
                                  name="tokensymbol"
                                  onChange={handleChange}
                                  value={data.tokensymbol}
                                />
                              </div>
                              <div className="mt-3">
                                <Input
                                  label={"Total Supply "}
                                  placeholder={"Token Total Supply"}
                                  required={true}
                                  name="totalsupply"
                                  onChange={handleChange}
                                  value={data.totalsupply}
                                  type="number"
                                  optional={" (how many total tokens)"}
                                />
                              </div>
                              <InfoInput
                                link={"https://t.me/"}
                                label={"Telegram"}
                                name="telegram"
                                onChange={handleChange}
                                value={data.telegram}
                                optional={true}
                              />
                              <InfoInput
                                link={" https://x.com/"}
                                label={"Twitter"}
                                name="twitter"
                                onChange={handleChange}
                                value={data.twitter}
                                optional={true}
                              />
                              <InfoInput
                                link={"https://"}
                                label={"Website"}
                                name="website"
                                onChange={handleChange}
                                value={data.website}
                                optional={true}
                              />
                              <div className="row">
                                <div className="col mt-4">
                                  <label>Short Description</label>

                                  <textarea
                                    rows="6"
                                    style={{
                                      borderRadius: "8px",
                                      padding: "12px 15px",
                                      width: "100%",
                                    }}
                                    name="description"
                                    onChange={handleChange}
                                    value={data.description}
                                  ></textarea>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card mt-3" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">Distribution</h5>
                                  <p>
                                    Setup how your coin should be initially
                                    distributed.
                                  </p>
                                </div>
                                <div className="line"></div>
                              </div>
                              <>
                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <p> Team Allocation</p>
                                      <p>(How much is reserved for you)</p>
                                    </div>
                                    <div className="form-check form-switch">
                                      <input
                                        onChange={toggleAccordion}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={isOpen}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {isOpen && (
                                  <>
                                    <div className="row">
                                      <label>Team supply allocation</label>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          alignItems: "center",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div style={{ flex: 1 }}>
                                          <input
                                            type="range"
                                            min={0}
                                            max={25}
                                            name="teamPercent"
                                            value={data.teamPercent || 0}
                                            onChange={handleChange}
                                          />
                                          <p> {data?.teamPercent || 0}%</p>
                                        </div>

                                        <div
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <h3>=</h3>
                                        </div>
                                        <Input
                                          label={""}
                                          name={"teamsupply"}
                                          value={
                                            data.totalsupply &&
                                            data.teamPercent &&
                                            (Number(data.totalsupply) / 100) *
                                              data.teamPercent
                                          }
                                          onChange={() =>
                                            console.log("TV changed")
                                          }
                                          disabled={true}
                                          style={{ flex: 1 }}
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <Input
                                        label={"Team supply address "}
                                        placeholder={"0x"}
                                        onFocus={(e) =>
                                          (e.target.placeholder = "")
                                        }
                                        onBlur={(e) =>
                                          (e.target.placeholder = "0x")
                                        }
                                        required={true}
                                        name="teamAddress"
                                        value={data.teamAddress}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <p>
                                      The address that will receive the team
                                      supply when the token is launched. (Click
                                      the red button, top left of this site, to
                                      find your receive address)
                                    </p>
                                    <br />
                                  </>
                                )}
                              </>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card" style={{ padding: "12px" }}>
                          {" "}
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">
                                    Anti-bot Protection
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "lightgray",
                                      }}
                                    >
                                      {"  Optional"}
                                    </span>
                                  </h5>
                                  <p>
                                    Optionally enable these features to protect
                                    your token launch from bots and snipers.
                                  </p>
                                </div>
                                <div className="line"></div>
                              </div>

                              <div className="row">
                                <p className="mt-3 mb-3">
                                  Once you launch your coin, you can only
                                  increase or disable your anti-bot limits.
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <p>Limit Per Wallet</p>
                                  </div>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={isLimitPerWalletOpen}
                                      onChange={toggleLimitPerWalletAccordion}
                                    />
                                  </div>
                                </div>
                              </div>

                              {isLimitPerWalletOpen && (
                                <>
                                  <div className="row">
                                    <label>Max tokens per wallet</label>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>
                                        <input
                                          type="range"
                                          min={0}
                                          max={10}
                                          name="maxWallet"
                                          value={data.maxWallet || 0}
                                          onChange={handleChange}
                                        />
                                        <p> {data?.maxWallet || 0}%</p>
                                      </div>
                                      <div
                                        style={{
                                          alignItems: "center",
                                          justifyContent: "center",
                                          margin: "10px",
                                        }}
                                      >
                                        <h3>=</h3>
                                      </div>
                                      <Input
                                        disabled={true}
                                        value={
                                          data.totalsupply &&
                                          data.maxWallet &&
                                          (Number(data.totalsupply) / 100) *
                                            Number(data.maxWallet)
                                        }
                                        style={{ flex: 1 }}
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    The maximum share of the total supply a
                                    single wallet address can hold
                                  </p>
                                  <br />
                                </>
                              )}

                              <div className="row">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <p>Limit Per Transaction</p>
                                  </div>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={isLimitPerTransactionOpen}
                                      onChange={
                                        toggleLimitPerTransactionAccordion
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              {isLimitPerTransactionOpen && (
                                <>
                                  <div className="row">
                                    <label>Max tokens per transaction</label>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div>
                                        <input
                                          type="range"
                                          min={0}
                                          max={10}
                                          name="maxTx"
                                          value={data.maxTx || 0}
                                          onChange={handleChange}
                                        />
                                        <p> {data?.maxTx || 0}%</p>
                                      </div>
                                      <div
                                        style={{
                                          alignItems: "center",
                                          justifyContent: "center",
                                          margin: "10px",
                                        }}
                                      >
                                        <h3>=</h3>
                                      </div>
                                      <Input
                                        placeholder={""}
                                        disabled={true}
                                        value={
                                          data.totalsupply &&
                                          data.maxTx &&
                                          (Number(data.totalsupply) / 100) *
                                            Number(data.maxTx)
                                        }
                                        style={{ flex: 1 }}
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    The maximum number of tokens that can be
                                    traded in a single trade
                                  </p>{" "}
                                  <br />
                                </>
                              )}
                            </form>
                          </div>
                        </div>
                        <div className="card mt-3" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">
                                    Coin trade taxes{" "}
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "lightgray",
                                      }}
                                    >
                                      {"  Optional"}
                                    </span>
                                  </h5>
                                  <p>
                                    Optionally add a buy and/or sell tax that is
                                    charged when trading the coin. Taxes are
                                    paid out to a receive address you specify.
                                  </p>
                                </div>
                                <div className="line"></div>
                              </div>
                              <>
                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <p>Buy Tax</p>
                                    </div>

                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={isBuyTaxOpen}
                                        onChange={toggleBuyTaxAccordion}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {isBuyTaxOpen && (
                                  <>
                                    <div className="row">
                                      <div style={{ marginTop: "10px" }}>
                                        <div>
                                          <input
                                            type="range"
                                            min={0}
                                            max={25}
                                            name="buyTax"
                                            value={data.buyTax || 0}
                                            onChange={handleChange}
                                          />
                                          <p> {data?.buyTax || 0}%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>

                              <div className="row">
                                <br />
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <p>Sell Tax</p>
                                  </div>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={isSellTaxOpen}
                                      onChange={toggleSellTaxAccordion}
                                    />
                                  </div>
                                </div>
                              </div>

                              {isSellTaxOpen && (
                                <>
                                  <div className="row">
                                    <div
                                      style={{
                                        gap: "10px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div>
                                        <input
                                          type="range"
                                          min={0}
                                          max={25}
                                          name="sellTax"
                                          value={data.sellTax || 0}
                                          onChange={handleChange}
                                        />
                                        <p> {data?.sellTax || 0}%</p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {(isSellTaxOpen || isBuyTaxOpen) && (
                                <>
                                  <div className="row">
                                    <Input
                                      label={"Tax payout address "}
                                      placeholder={"0x"}
                                      required={true}
                                      name="taxWallet"
                                      value={data.taxWallet}
                                      onChange={handleChange}
                                      onFocus={(e) =>
                                        (e.target.placeholder = "")
                                      }
                                      onBlur={(e) =>
                                        (e.target.placeholder = "0x")
                                      }
                                    />
                                  </div>
                                  <p>
                                    This is the address to which earned taxes
                                    will be paid out. (Click the red button, top
                                    left of this site, to find your receive
                                    address)
                                  </p>
                                  <br />
                                </>
                              )}
                            </form>
                          </div>
                        </div>
                        <div className="card mt-3" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">Initial liquidity</h5>
                                  <p>
                                    Pair the remainder of your token supply with
                                    an amount of{" "}
                                    {currentNetwork?.currencySymbol || "ETH"}{" "}
                                    you choose to set up the initial liquidity
                                    pool. You can do this after your token is
                                    launched, but we recommend doing it when you
                                    launch your token.
                                  </p>
                                </div>
                                <div className="line"></div>
                              </div>
                              <>
                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <p className="text-center">
                                        Fund the{" "}
                                        {currentNetwork?.exchangeName ||
                                          "Uniswap"}{" "}
                                        pool on launch
                                      </p>
                                    </div>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={isUniswapOpen}
                                        onChange={toggleIsUniswapAccordion}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {isUniswapOpen && (
                                  <>
                                    <div className="row">
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <label>
                                          Deposit{" "}
                                          {currentNetwork?.currencySymbol ||
                                            "ETH"}{" "}
                                        </label>
                                        <label>Deposit Tokens</label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Input
                                          placeholder={"0"}
                                          name="liquidityEth"
                                          value={data.liquidityEth}
                                          onChange={handleChange}
                                          type="number"
                                        />
                                        <Input
                                          disabled={true}
                                          value={tokensToLiq}
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="mt-5">
                                        Marketcap at launch
                                        {" " + usdMCap + "$"}
                                      </div>
                                    </div>
                                    <br />
                                  </>
                                )}

                                {isUniswapOpen && (
                                  <>
                                    <div className="row">
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          <p> Burn Liquidity</p>
                                        </div>
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={isBurnOpen}
                                            onChange={toggleIsBurnAccordion}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <br />
                                  </>
                                )}
                                {isBurnOpen && isUniswapOpen && (
                                  <>
                                    <div className="row">
                                      <h5 style={{ color: "red" }}>
                                        Please make sure what you are doing,
                                        checking this option will burn your
                                        liquidity tokens and it is unrevertable.
                                        You won’t be able to recover this
                                        initial investment, it will remain as
                                        permanent liquidity.
                                      </h5>
                                    </div>
                                    <br />
                                  </>
                                )}
                              </>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">Network</h5>
                                  <p>
                                    Please choose your network carefully and
                                    make sure you confirm network change on the
                                    wallet.
                                  </p>
                                </div>
                                <div className="line"></div>
                              </div>
                              <SwitchNetwork />
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                className="mt-3"
                              >
                                <div>
                                  <p>Verified Source Code</p>
                                </div>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={true}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                className="mt-3"
                              >
                                <div>
                                  <p>No Copyright Link</p>
                                </div>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={true}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card mt-3" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">Agreement</h5>
                                  <div style={{ display: "flex" }}>
                                    <Form.Check
                                      aria-label="option 1"
                                      onChange={(e) =>
                                        setterms(e.target.checked)
                                      }
                                      checked={terms}
                                    />

                                    <p style={{ marginLeft: "6px" }}>
                                      I have read, understood and agreed to the
                                      <span
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleShow}
                                      >
                                        {" "}
                                        Terms of Use.
                                      </span>
                                    </p>
                                  </div>
                                  <Input
                                    label={"Your Email Address"}
                                    placeholder={
                                      "Receive your contract address link"
                                    }
                                    value={mail}
                                    onChange={(e) => setmail(e.target.value)}
                                    type="email"
                                    optional={" Optional"}
                                  />
                                </div>
                                <div className="line"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card mt-3" style={{ padding: "12px" }}>
                          <div className="list details">
                            <form action="#" className="form-box">
                              <div className="row">
                                <div className="col">
                                  <h5 className="mb-14">Transaction</h5>
                                  <div className="row">
                                    <div className="col-6">
                                      <p>
                                        Comission Fee:
                                        <span>
                                          {" "}
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip>
                                                The commission fee will be
                                                transferred automatically to us
                                                during the contract creation. In
                                                case of error, this amount will
                                                not be deducted from your
                                                wallet. Only the gas fees will
                                                be deducted.
                                              </Tooltip>
                                            }
                                          >
                                            <i className="fa-solid fa-circle-info"></i>
                                          </OverlayTrigger>
                                        </span>
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <h5>
                                        <Badge
                                          bg="success"
                                          style={{ width: "100%" }}
                                        >
                                          {feeData
                                            ? Number(
                                                removeDecimal(feeData, 18, 4)
                                              ) +
                                              " " +
                                              currentNetwork.currencySymbol
                                            : "Connect To See "}
                                        </Badge>
                                      </h5>
                                    </div>
                                  </div>
                                  {isUniswapOpen && data?.liquidityEth && (
                                    <div className="row">
                                      <div className="col-6">
                                        <p>
                                          Liquidity Amount:
                                          <span>
                                            {" "}
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  This is going to be used to
                                                  fund the dex liquidity of your
                                                  token.
                                                </Tooltip>
                                              }
                                            >
                                              <i className="fa-solid fa-circle-info"></i>
                                            </OverlayTrigger>
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <h5>
                                          <Badge
                                            bg="success"
                                            style={{ width: "100%" }}
                                          >
                                            {data.liquidityEth +
                                              " " +
                                              currentNetwork.currencySymbol}
                                          </Badge>
                                        </h5>
                                      </div>
                                    </div>
                                  )}
                                  <div className="row">
                                    <div className="col-6">
                                      <p>
                                        Gas Fee:
                                        <span>
                                          {" "}
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip>
                                                The gas fee depend on gas limit
                                                and gas price. Your wallet will
                                                automatically display the best
                                                fee to use.
                                              </Tooltip>
                                            }
                                          >
                                            <i className="fa-solid fa-circle-info"></i>
                                          </OverlayTrigger>
                                        </span>
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <h5>
                                        <Badge
                                          bg="info"
                                          style={{ width: "100%" }}
                                        >
                                          Variable
                                        </Badge>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="line"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                        {address && isConnected ? (
                          <Button
                            variant="success"
                            className="mt-2"
                            style={{ width: "100%" }}
                            onClick={handleNext}
                          >
                            Deploy
                          </Button>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <ConnectButton />
                          </div>
                        )}
                      </div>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>
                            Terms of Service: CoinBuilder.pro
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            The CoinBuilder.pro Token Generator provides a
                            streamlined service for the automatic creation of
                            ERC20 and BEP20 tokens. As the user, you—or any
                            designated transferee—will retain full ownership and
                            control over the tokens, along with all associated
                            responsibilities regarding their use on the Ethereum
                            blockchain.
                          </p>
                          <p>
                            We do not hold, store, or control your tokens at any
                            point. Additionally, we are not liable for their
                            usage or any financial losses that may occur.
                          </p>
                          <p>
                            All transactions involving your tokens are conducted
                            through your MetaMask wallet, or any other
                            cryptocurrency wallet connect, are independent
                            third-party applications. We disclaim any
                            responsibility related to the use of MetaMask, other
                            browser compatible wallets, or desktop or mobile
                            wallets, and any outcomes that may arise from its
                            use.
                          </p>
                          <p>
                            Please be aware that your Ethereum public address
                            and its contents may be visible to others when using
                            the Token Maker. We are not responsible for the
                            interactions with MetaMask, the Ethereum network,
                            the Binance Smart Chain, or any other third parties
                            involved in token creation.
                          </p>
                          <p>
                            We have no oversight over transactions made through
                            MetaMask, the Ethereum network, or the Binance Smart
                            Chain, or any of the other blockchains and layer 2
                            applications used in token generation and cannot
                            cancel or refund any transactions. You are solely
                            responsible for these transactions, as well as for
                            securing your tokens and any associated fiat or
                            cryptocurrency funds.
                          </p>
                          <p>
                            When using the CoinBuilder.pro application to create
                            tokens, please understand that all actions performed
                            on blockchain networks like Ethereum or Binance
                            Smart Chain are beyond our control and are
                            irreversible.
                          </p>
                          <p>
                            Cryptocurrency prices are inherently volatile, which
                            could impact the value of your token. You are also
                            responsible for any taxes or costs related to your
                            token and the transactions made with it.
                          </p>
                          <p>
                            We are not liable for losses related to hardware or
                            software malfunctions, internet connectivity issues,
                            cyberattacks, or unauthorized access to your wallet.
                          </p>
                          <p>
                            Network changes or upgrades to the Ethereum network
                            or Binance Smart Chain, Polygon, Avalanche, Fantom,
                            Arbitrum, Optimism or other chains may affect your
                            token; such changes are outside our control and
                            responsibility.
                          </p>
                          <p>
                            Network changes or upgrades to the Ethereum network
                            or Binance Smart Chain, Polygon, Avalanche, Fantom,
                            Arbitrum, Optimism or other chains may affect your
                            token; such changes are outside our control and
                            responsibility.
                          </p>
                          <p>
                            It is crucial to be aware of and comply with all
                            relevant laws and regulations in your country and
                            jurisdiction and in any regions where you operate,
                            concerning cryptocurrencies and blockchain
                            technology. We disclaim any liability for
                            non-compliance with these legal requirements.
                          </p>
                        </Modal.Body>
                      </Modal>
                      {/* Token info */}
                    </>
                  )}
                </div>
              </div>
            </section>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
