import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import contract from "./tokencontract";
import { encodeParameters } from "web3-eth-abi";
import axios from "axios";

// Token balance'ına ondalık eklemek için
export function removeDecimal(balance, decimal, fixed) {
  if (Number(balance) > 0) {
    if (decimal > 0) {
      const balanceBig = new BigNumber(String(balance));
      const decimalBig = new BigNumber(String(10)).exponentiatedBy(
        String(decimal)
      );
      const result = new BigNumber(balanceBig.dividedBy(decimalBig));
      return result.toFixed(fixed);
    } else {
      const balanceBig = new BigNumber(String(balance));
      return balanceBig.toFixed(fixed);
    }
  } else {
    return "0";
  }
}

// Token balance'ından ondalık çıkarmak için
export function addDecimal(balance, decimal, fixed) {
  if (Number(balance) > 0) {
    if (decimal > 0) {
      const balanceBig = new BigNumber(String(balance));
      const decimalBig = new BigNumber(String(10)).exponentiatedBy(
        String(decimal)
      );
      const result = new BigNumber(balanceBig.multipliedBy(decimalBig));
      return result.toFixed(fixed);
    } else {
      const balanceBig = new BigNumber(String(balance));
      return balanceBig.toFixed(fixed);
    }
  } else {
    return "0";
  }
}

export const getCode = (data) => {
  const telegramText = data.telegram
    ? `// Telegram: https://t.me/${data.telegram}`
    : "";
  const twitterText = data.twitter
    ? `// Twitter: https://x.com/${data.twitter}`
    : "";
  const websiteText = data.website ? `// Website: https://${data.website}` : "";

  const descriptionText = data.description ? `/* ${data.description} */` : "";

  return addToLines(
    contract,
    telegramText,
    twitterText,
    websiteText,
    descriptionText,
    2
  );
};

function addToLines(ilkMetin, ek1, ek2, ek3, ek4, eklenecekSatir) {
  function addTextBelowLine(longString, targetLine, newText) {
    const lines = longString.split("\n");

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes(targetLine)) {
        // Belirtilen satırı bulduk
        // Yeni metni bir sonraki satıra ekleyelim
        lines.splice(i + 1, 0, newText);
        break; // İşlemi tamamladık, döngüden çıkabiliriz
      }
    }

    return lines.join("\n");
  }

  const salt1 = ethers.randomBytes(32);
  const salt = ethers.hexlify(salt1);
  const lineHash = `  bytes32 public CREATION_HASH=${salt};`;

  const sonmetin = addTextBelowLine(
    ilkMetin,
    "contract TronTrump is ERC20, Ownable {",
    lineHash
  );

  // İlk metni diziye böl
  let satirDizisi = sonmetin.split("\n");

  // Belirtilen satırdan itibaren yeni satırları ekleyin
  satirDizisi.splice(eklenecekSatir, 0, ek1, ek2, ek3, ek4);

  // Diziyi tekrar birleştirin
  let yeniMetin = satirDizisi.join("\n");

  return yeniMetin;
}

export const encodeConstructorArguments = (
  _tokenName,
  _tokenSymbol,
  _decimals,
  addresses,
  amounts,
  factory
) => {
  const types = [
    "string",
    "string",
    "uint8",
    "address[4]",
    "uint256[8]",
    "address",
  ];
  const values = [
    _tokenName,
    _tokenSymbol,
    _decimals,
    addresses,
    amounts,
    factory,
  ];

  const encodedArguments = encodeParameters(types, values);
  return encodedArguments.slice(2);
};

export const verifyContract = async (
  contractaddress,
  sourceCode,
  constructorArguments,
  setDeploymentStatus,
  setDeploymentLoading,
  setDeploymentDesc,
  currentNetwork
) => {
  const verification = {
    apikey: currentNetwork.etherscanApiKey,
    module: "contract",
    action: "verifysourcecode",
    contractaddress: contractaddress,
    codeformat: "solidity-single-file",
    contractname: "TronTrump",
    compilerversion: "v0.8.26+commit.8a97fa7a",
    optimizationUsed: "0",
    constructorArguements: constructorArguments,
    sourceCode: sourceCode,
    runs: 200,
  };
  try {
    const postEthScan = async (data, cb) => {
      let retryCount = 0;
      const maxRetries = 5; // Döngüyü en fazla 10 kez tekrar et

      window.localStorage.setItem("tokenInfo", JSON.stringify(verification));
      console.log(verification, "verification body");
      const tryPost = async () => {
        const result = await axios.post(
          currentNetwork.etherscanApiUrl,
          verification,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        console.log(result);
        if (result.data.status === "0" && retryCount < maxRetries) {
          // Hata durumu, ama henüz maksimum tekrar sayısına ulaşmadık
          console.log("failed: ", retryCount);
          retryCount++;
          setTimeout(tryPost, 12000);
        } else {
          // Başarılı veya maksimum tekrar sayısına ulaşıldı
          if (result.data.status === "0") {
            console.log("Max retry reached, failed transaction.");
            if (retryCount == 4) {
              setDeploymentStatus(
                `Contract couldn't verify : ${data.logs[0].address}`
              );
              setDeploymentDesc("Please contact us as soon as possible");
              setDeploymentLoading(false);
            }
          }
          cb(data);
        }
      };

      tryPost();
    };

    postEthScan(verification, (data) => {
      console.log("////////////CONTRACT VERIFIED/////////");
      setDeploymentStatus(`Your Contract Verified`);
      setDeploymentDesc("You can go back home or create new token.");
      setDeploymentLoading(false);
    });
  } catch (error) {
    console.error("Hata oluştu:", error);
  }
};
